import arrowIcon from '../img/arrow.svg';
import stepArrowMobileIcon from '../img/step-arrow-mobile.svg';
import stepArrowDesktopIcon from '../img/step-arrow-desktop.svg';
import sliderArrow from '../img/slider-arrow.svg';
import doubleArrowDown from '../img/double-arrow-down.svg';
import playButtonIcon from '../img/play-button.svg';
import pauseButtonIcon from '../img/pause-button.svg';
import miniPlayButtonIcon from '../img/mini-play-button.svg';
import miniPauseButtonIcon from '../img/mini-pause-button.svg';
import playIcon from '../img/play-icon.svg';
import pauseIcon from '../img/pause-icon.svg';
import linkedinIcon from '../img/linkedin.svg';
import linkedinTransparentIcon from '../img/linkedin--transparent.svg';
import facebookIcon from '../img/facebook.svg';
import twitterIcon from '../img/twitter.svg';
import europeIcon from '../img/europe.svg';
import phoneIcon from '../img/phone-icon.svg';
import flagENIcon from '../img/flag-en-icon.svg';
import flagPLIcon from '../img/flag-pl-icon.svg';
import flagDEIcon from '../img/flag-de-icon.svg';
import flagESIcon from '../img/flag-es-icon.svg';
import flagFRIcon from '../img/flag-fr-icon.svg';

import bulletListCheckmark from '../img/bullet-list-checkmark.svg';

import languageIcon from '../img/language-icon.svg';
import dropdownIcon from '../img/arrow-dropdown.svg';
import dropdownIcon2 from '../img/dropdown-icon.svg';
import externalLinkIcon from '../img/external-link-icon.svg';
import searchIcon from '../img/search.svg';
import closeIcon from '../img/close.svg';
import closeIcon2 from '../img/close-icon.svg';
import downloadCircleIcon from '../img/download-circle.svg';
import informationCircle from '../img/information-circle.svg';
import questionCircle from '../img/question-circle.svg';
import announcementBlob1 from '../img/announcement-blob-1.svg';
import announcementBlob2 from '../img/announcement-blob-2.svg';
import formSectionV2Blob1 from '../img/form-section-v2-blob-1.svg';
import formSectionV2Blob2 from '../img/form-section-v2-blob-2.svg';
import videoSectionBlob1 from '../img/video-section-blob-1.svg';
import videoSectionBlob2 from '../img/video-section-blob-2.svg';
import videoSectionBlob3 from '../img/video-section-blob-3.svg';
import landingPageNewsletterFormBlob1 from '../img/landing-page-newsletter-form-blob-1.svg';
import landingPageNewsletterFormBlob2 from '../img/landing-page-newsletter-form-blob-2.svg';
import tableOfContentsIcon from '../img/table-of-contents-icon.svg';
import popupCloseIcon from '../img/popup-close-icon.svg';
import popupCloseSmallIcon from '../img/popup-close-small-icon.svg';
import eventSummaryBlob1 from '../img/event-summary-blob-1.svg';
import eventSummaryBlob2 from '../img/event-summary-blob-2.svg';

import announcementBox from '../img/blocks-previews/announcement-box.png';
import blogPostsList from '../img/blocks-previews/blog-posts-list.png';
import blogPostsSection from '../img/blocks-previews/blog-posts-section.png';
import caseStudySumUpSection from '../img/blocks-previews/case-study-sum-up-section.png';
import landingHeader from '../img/blocks-previews/landing-header.png';
import CalendarSection from '../img/blocks-previews/calendar-section.png';
import tableSection from '../img/blocks-previews/table-section.png';
import titleSection from '../img/blocks-previews/title-section.png';
import tierListSection from '../img/blocks-previews/tier-list-section.png';
import benefitsSection from '../img/blocks-previews/benefits-section.png';
import benefitsSection2 from '../img/blocks-previews/benefits-section-2.png';
import bulletList from '../img/blocks-previews/bullet-list.png';
import separatorSection from '../img/blocks-previews/separator.png';
import playerSection from '../img/blocks-previews/player-section.png';
import playerSectionV2 from '../img/blocks-previews/player-section-v2.png';
import twoColumnsSection from '../img/blocks-previews/two-columns-section.png';
import twoCardsSection from '../img/blocks-previews/two-cards-section.png';
import caseStudyItemsSection from '../img/blocks-previews/case-study-items-section.png';
import ctaBar from '../img/blocks-previews/cta-bar.png';
import ctaCard from '../img/blocks-previews/cta-card.png';
import ctaForm from '../img/blocks-previews/cta-form.png';
import newsletterForm from '../img/blocks-previews/newsletter-form.png';
import downloadFilesSection from '../img/blocks-previews/download-files-section.png';
import downloadSection from '../img/blocks-previews/download-section.png';
import exposedTextSection from '../img/blocks-previews/exposed-text-section.png';
import eventSummary from '../img/blocks-previews/event-summary.png';
import logoSectionV2 from '../img/blocks-previews/logo-section-v2.png';
import logoSectionV3 from '../img/blocks-previews/logo-section-v3.png';
import faqSection from '../img/blocks-previews/faq-section.png';
import featureList from '../img/blocks-previews/feature-list.png';
import sliderSection from '../img/blocks-previews/slider-section.png';
import quoteSection from '../img/blocks-previews/quote-section.png';
import secondaryHeader from '../img/blocks-previews/secondary-header.png';
import gridSection from '../img/blocks-previews/grid-section.png';
import ourTargetGroup from '../img/blocks-previews/our-target-group.png';
import aboutUsSection from '../img/blocks-previews/about-us-section.png';
import statisticsSection from '../img/blocks-previews/statistics-section.png';
import statisticsSectionV2 from '../img/blocks-previews/statistics-section-v2.png';
import socialProofCategoriesSection from '../img/blocks-previews/social-proof-categories-section.png';
import lifecycleSection from '../img/blocks-previews/lifecycle-section.png';
import landingPageNewsletterForm from '../img/blocks-previews/landing-page-newsletter-form.png';
import stepListSection from '../img/blocks-previews/step-list-section.png';
import stepListSectionV2 from '../img/blocks-previews/step-list-section-v2.png';
import shortQuotesSection from '../img/blocks-previews/short-quotes-section.png';
import formSection from '../img/blocks-previews/form-section.png';
import formSectionV2 from '../img/blocks-previews/form-section-v2.png';
import referencesSection from '../img/blocks-previews/references-section.png';
import roiCalculator from '../img/blocks-previews/roi-calculator.png';
import textEditorSection from '../img/blocks-previews/text-editor-section.png';
import typesOfFacilitiesSection from '../img/blocks-previews/types-of-facilities-section.png';
import imageSection from '../img/blocks-previews/image.png';
import implementationStageTimelineSection from '../img/blocks-previews/implementation-stage-timeline-section.png';
import mentionedBySection from '../img/blocks-previews/mentioned-by-section.png';
import videoSection from '../img/blocks-previews/video-section.png';
import videoTwoColumnsSection from '../img/blocks-previews/video-two-columns-section.png';
import testimonialSection from '../img/blocks-previews/testimonial-section.png';

import imageNotFound from '../img/not-found.png';

import separator from '../img/separator.svg';

import wavesTwoColumnsSection from '../img/waves/waves-two-columns-section.svg';
import wavesCTABar from '../img/waves/waves-cta-bar.svg';
import wavesCTACard from '../img/waves/waves-cta-card.svg';
import wavesCTACardMobile from '../img/waves/waves-cta-card-mobile.svg';
import wavesDownloadSection from '../img/waves/waves-download-section.svg';
import wavesRoiCalculator from '../img/waves/waves-roi-calculator.svg';
import wavesHeroWhite from '../img/waves/waves-hero.svg';
import wavesTestimonialSection from '../img/waves/waves-testimonial-section.svg';

import formSectionArrow from '../img/form-section/form-section-arrow.svg';
import formSectionBlob1 from '../img/form-section/form-section-blob-1.svg';
import formSectionBlob2 from '../img/form-section/form-section-blob-2.svg';

import '../../../includes/views/components/organisms/header/header';
import '../../../includes/views/components/molecules/calendar/calendar';
import '../../../includes/views/components/molecules/player/player';
import '../../../includes/views/components/molecules/quotations/quotations';
import '../../../includes/views/components/organisms/player-section/player-section';
import '../../../includes/views/components/organisms/player-section-v2/player-section-v2';
import '../../../includes/views/components/organisms/two-columns-section/two-columns-section';
import '../../../includes/views/components/organisms/social-proof-categories-section/social-proof-categories-section';
import '../../../includes/views/components/organisms/blog-posts-section/blog-posts-section';
import '../../../includes/views/components/atoms/button/audio-button';
import '../../../includes/views/components/organisms/two-cards-section/two-cards-section';
import '../../../includes/views/components/organisms/slider-section/slider-section';
import '../../../includes/views/components/organisms/step-list-section/step-list-section';
import '../../../includes/views/components/organisms/tier-list-section/tier-list-section';
import '../../../includes/views/components/organisms/tier-list-section/tier-list-section-popup';
import '../../../includes/views/components/organisms/feature-list/feature-list';
import '../../../includes/views/components/organisms/breadcrumbs/breadcrumbs';
import '../../../includes/views/components/organisms/news-bar/news-bar';
import '../../../includes/views/components/organisms/short-quotes-section/short-quotes-section';
import '../../../includes/views/components/atoms/checkbox/checkbox';
import '../../../includes/views/components/atoms/input/input';
import '../../../includes/views/components/atoms/select/select';
import '../../../includes/views/components/atoms/textarea/textarea';
import '../../../includes/views/components/molecules/form/form';
import '../../../includes/views/components/organisms/blog-nav/blog-nav';
import '../../../includes/views/components/organisms/logo-section-v2/logo-section-v2';
import '../../../includes/views/components/organisms/testimonial-section/testimonial-section';
import '../../../includes/views/components/organisms/faq-section/faq-section';
import '../../../includes/views/components/organisms/implementation-stage-timeline-section/implementation-stage-timeline-section';
import '../../../includes/views/components/organisms/table-of-contents/table-of-contents';
import '../../../includes/views/components/organisms/types-of-facilities-section/types-of-facilities-section';
import '../../../includes/views/components/organisms/our-target-group/our-target-group';
import '../../../includes/views/components/organisms/form-section-v2/form-section-v2';
import '../../../includes/views/components/organisms/references-section/references-section';
import '../../../includes/views/components/organisms/roi-calculator/roi-calculator';
import '../../../includes/views/components/organisms/video-section/video-section';
import '../../../includes/views/components/organisms/video-two-columns-section/video-two-columns-section';
import '../../../includes/views/components/molecules/search-form/search-form';
import '../../../includes/views/components/molecules/request-demo-form/request-demo-form';
import '../../../includes/views/components/organisms/case-study-sum-up-section/case-study-sum-up-section';
import '../../../includes/views/components/atoms/cookiebot-toggler/cookiebot-toggler';
import './lazyLoading.js';

window.addEventListener('load', () => {
  document.body.classList.remove('js-preload');
});